export const en = {
  meta_title_home:
    "Car service Petit Garage Ploiesti ⭐ Car service Ploiesti Prahova",
  meta_desc_home:
    "Petit Garage offers a full range of automotive services, including damage report center, auto mechanics, auto electrics, body shop, auto painting and computerized testing",
  meta_keywords_home:
    "Car service Ploiesti, car damage Ploiesti, car mechanics, car tinting Ploiesti, car painting Ploiesti.",

  meta_title_services:
    "Car service Petit Garage Ploiesti ⭐ Quality car services",
  meta_desc_services:
    "Our services include: car damage survey, car air conditioning, mechanics, paint shop, 3D wheel geometry, computerized testing. ⭐ Address: Ploiesti, 66, Sondelor str., Prahova.",
  meta_keywords_services:
    "Damage assessment centre, Car air conditioning, Car mechanics, Car body shop, 3D wheel geometry, Car paint shop, Headlight polymerization, Computerized testing",

  meta_title_contact:
    "Car service Petit Garage ⭐ Sondelor, nr. 66, Ploiesti Prahova",
  meta_desc_contact:
    "Car service Petit Garage address Ploiesti, str. Sondelor, nr. 66, Jud. Prahova.⭐ Working hours: Monday-Friday: 8:30 - 16:30 / Saturday - Sunday: Closed. Phone: 0758 839 110.",
  meta_keywords_contact:
    "Damage assessment centre Ploiesti, Car air conditioning Ploiesti, Car mechanic Ploiesti, Car body shop Ploiesti, 3D wheel geometry Ploiesti, Car painting Ploiesti, Headlight polimerization Ploiesti, Computerized testing Ploiesti.",

  page_home: "Home",
  page_services: "Services",
  page_contact: "Contact",
  page_terms: "GDPR",

  header_callnow: "Call now",

  contact_heading: "We're at your service!",
  contact_location: "Ploiești, str. Sondelor, nr. 66, Jud. Prahova.",
  contact_seeform: <p>See the form</p>,
  contact_extra_flavour:
    "Petit Garage is an auto service that is part of Swiso Impex.",
  contact_extra_contact: "Contact: RO11BACX0000002454534000",
  contact_extra_bank: "Bank: UNICREDIT BANK SA",

  missing_errormsg: "We're sorry but we couldn't find that page",

  thankyou: (
    <h1>
      We thank you for your request! One of our consultants will be with you
      shortly.
    </h1>
  ),
  thankyou_heading: "Thank you for contacting us!",
  thankyou_message:
    "Your request was sent successfully and we will contact you as soon as possible!",

  landing_hero_hours_open: "MONDAY-FRIDAY: 8:30 - 16:30",
  landing_hero_hours_closed: "SATURDAY-SUNDAY: CLOSED",
  landing_btn_seeservices: "CONTACT US NOW",
  landing_suggestion_flavour:
    "If you are looking for a trustworthy partner on the field of auto services in Ploiești, we are your destination!",
  landing_ourteam: "Our team",
  landing_testimonials: "Testimonials",
  landing_cta_concerns: "Do you have any concerns?",
  landing_btn_contactus: "Contact us",

  employee_ceo: "Chief Executive Officer",
  employee_cto: "Chief Technical Officer",
  employee_cfo: "Chief Financial Officer",
  employee_coo: "Director administrativ",
  employee_car_damage: "Car damages manager",
  employee_marketer: "Marketing specialist",
  employee_accountant: "Accountant",
  employee_mechanic: "Mechanic",
  employee_bodyworks: "Bodyworks specialist",

  services_card_1_title: "Flexibility and versatility",
  services_card_1_text:
    "Petit Garage perfectly understands our customers problem regarding the blocking of vehicles in the service, which is why we perform quality services, folded to the needs of the customer, while assuring you that we work intensively to complete the work in the shortest possible time.",
  services_card_3_title: "Cutting-edge equipment",
  services_card_3_text:
    "Our service is composed with a dynamic and ingenious staff! A team we proudly consider our strong point. Emphasizing quality over quantity. With the help of effective communication and experience in this field, we provide our customers with fast solutions, carrying out necessary repairs in the shortest time possible.",

  services_btn_seeform: "See the contact form",

  services_car_damage: "Car damage assistance",
  services_car_damage_info: (
    <div style={{ gap: "16px" }}>
      <p>
        We offer assistance and specialized repairs using quick and efficient
        solutions for damaged cars. If you choose us, you can benefit from free
        services such as:
      </p>
      <ul style={{ textAlign: "left" }}>
        <li>
          <p>Assistance in preparing the claim file;</p>
        </li>
        <li>
          <p>
            Managing the file in relation to the insurance company involved;
          </p>
        </li>
        <li>
          <p>
            Receiving a replacement vehicle for the entire duration of the
            repairs;
          </p>
        </li>
        <li>
          <p>Transport provided for immobile vehicles;</p>
        </li>
        <li>
          <p>
            After the repairs are completed, you can pick up your vehicle
            without waiting for the insurance company to pay the service back;
          </p>
        </li>
        <li>
          <p>
            The promptness provided by our team of specialists regarding
            repairs;
          </p>
        </li>
      </ul>
      <p>
        This offer is valid only if our company handles the repairs of your car!
      </p>
      <p>Contact us now for more details at phone number +40762203320!</p>
      <p>
        We repair vehicles insured by any of the following companies: Omniasig,
        Groupama, Axeria, Grawe, Generali, Allianz, Allianz Țiriac, Asirom,
        Uniqa, Recrex.
      </p>
    </div>
  ),
  services_air_conditioning: "Air conditioning",
  services_air_conditioning_info:
    "Petit Garage now offers full checking and freon charging services for your car air conditioning system. We have purchased the most efficient car freon diagnostic and charging device from Texa - TEXA KONFORT 707R Off Road, which can treat both the standard R134A freon and the new ecological refrigerant R1234YF.",
  services_mechanics: "Mechanics",
  services_mechanics_info:
    "Petit Garage offers professional repair for all types of cars. Our team of experienced mechanics ensure high quality service using modern equipment and advanced techniques. Whatever your car problem is, we are here to help you quickly and efficiently. Contact us now for customized and reliable solutions for your needs.",
  services_tinware: "Tinware",
  services_tinware_info:
    "We provide professional car repair services in Ploiești. Our team uses advanced techniques and modern equipment to restore the original appearance of your car. We are here to provide you with customized solutions and excellent results. Contact us now for high quality auto repair services.",
  services_wheel_geometry: "3D wheel geometry",
  services_wheel_geometry_info:
    "The correct geometry of the wheels is essential for the stability and the safety of your car. Misaligned wheels can cause uneven tire wear, shortening tire life and incurring additional replacement costs. Incorrect alignment can also affect handling, causing premature wear on the suspension and steering.",
  services_paint_shop: "Paint shop",
  services_paint_shop_info:
    "Petit Garage offers expertise in vehicle painting. Our staff uses advanced methods and premium substances to restore the appearance of your vehicle. Regardless of the complexity or type of intervention, we ensure you a flawless result. Contact us now to give your car a special look on the road.",
  services_headlights: "Headlight polymerization",
  services_headlights_info:
    "Headlight polymerization is a process of cleaning and refurbishing the headlights of a car. Plastic headlights can become cloudy or yellowed due to exposure to sunlight and weather conditions. This process involves applying a chemical solution to remove deposits and imperfections, followed by polishing and sealing to prevent further damage. This improves the visibility and appearance of the car.",
  services_computerized_test: "Computerized car testing",
  services_computerized_test_info:
    "With computerized testing, your car is precisely and thoroughly evaluated, without guesswork or assumptions. Problems are quickly and accurately identified with the help of the \"Launch\" device and our specialized team. We can check error codes on all your vehicle's systems. Don't hesitate to schedule an appointment at our service center and let us take care of your car with the utmost care and professionalism!",

  form_failurealert:
    "An error has occured while sending the form. Please try again later!",
  form_placeholder_brand: "Brand",
  form_placeholder_model: "Model",
  form_placeholder_year: "Manufacturing year",
  form_placeholder_plate: "License plate number",

  form_placeholder_name: "Name",
  form_placeholder_phone: "Phone number",
  form_placeholder_email: "Email address",
  form_placeholder_desc: "Description of the problem",
  form_placeholder_notes: "Additional information",
  form_btn_submit: "Send",
  form_selectaservice: "Select a service",

  form_suggestion_title: "How can we help you?",
  form_suggestion_btn_seemore: "See more",

  tnc_title: "GDPR",
  tnc_h1: "1. WEBSITE INFORMATION",
  tnc_h1p1:
    "1.1 The website petitgrage.ro is owned by the company SWISO IMPEX S.R.L., headquartered in Florești, str. Principala, no. 17B, Prahova county, registered at the Trade Register under no. J29/452/2001, unique registration code RO 12727992.",
  tnc_h1p2:
    "Contact Address: Ploiești City, str. Sondelor, no. 66, Prahova county",
  tnc_h1p3: "Tel: 0758 839 110 - Bogdan Meret",
  tnc_h1p4: "E-mail: sef.service@swiso.ro",
  tnc_h1p5:
    "1.2 The terms and conditions on the petitgarage.ro website are designed to manage your use of this website. These terms will fully apply and will impact the way you use the site. By accessing this site or any page of this site, you agree to the terms and conditions contained in this document. Considering this, petitgarage.ro recommends reading this document.",

  tnc_h2: "2. GDPR AGREEMENT",
  tnc_h2p1:
    "2.1 Petitgarage.ro collects and processes personal data in accordance with the applicable legislation, in ways that ensure the confidentiality and adequate security of this data.",
  tnc_h2p2:
    "2.2 Processing purpose: The personal data of users are collected and processed for the purpose of subsequent contact.",
  tnc_h2p3:
    "2.3 The personal data of the user are kept by SWISO IMPEX S.R.L. throughout the entire operation period of the company.",

  tnc_h3: "3. RIGHTS OVER THE WEBSITE CONTENT",
  tnc_h3p1:
    "3.1 SWISO IMPEX S.R.L. will not transfer ownership rights of the software applications.",
  tnc_h3p2:
    "3.2 Petitgarage.ro holds complete and full rights over the content presented on the site.",
  tnc_h3p3:
    "3.3 Petitgarage.ro reserves the right to suspend, modify, add, or delete parts of its content at any time without prior notice. Moreover, Petitgarage.ro reserves the right to partially or fully restrict users' access to its content.",
  tnc_h3p4:
    "3.4 The entire content of petitgarage.ro is protected by copyright law, all rights reserved. Copying, displaying, publishing, or creating derivative materials is prohibited without confirmation from SWISO IMPEX S.R.L.",

  tnc_h4: "4. RESTRICTIONS",
  tnc_h4p1: "4.1 Users are strictly restricted from the following actions:",
  tnc_h4p2: "* using this site in any way that may cause harm",
  tnc_h4p3:
    "* using this site in any way that impacts users' access to the site",
  tnc_h4p4: "* using this site contrary to the applied regulations",
  tnc_h4p5:
    "* using this site for the purpose of collecting specific advertising or marketing information for another company",

  tnc_h5: "5. WEBSITE CONTENT",
  tnc_h5p1:
    '5.1 The terms and conditions of this site encompass any material posted on the site, audio files, videos, text, or any other material displayed on this site. By displaying the "Website Content," S.C. SWISO IMPEX S.R.L. has the power to modify the content of the site without prior notification to users.',
  tnc_h5p2:
    "5.2 The purpose of the website content is to provide clear and eloquent information to users about the services provided by the company SWISO IMPEX S.R.L.",

  tnc_h6: "6. LIMITATION OF LIABILITY",
  tnc_h6p1:
    "6.1 None of the employees of the company SWISO IMPEX S.R.L. shall be held liable for any indirect liability arising from the client's use of this site.",

  tnc_h7: "7. MODIFICATION OF THESE TERMS AND CONDITIONS",
  tnc_h7p1:
    "7.1 Petitgarage.ro reserves the right to modify these terms, by changing the version and date of adopting the new regulation without fulfilling other formalities.",

  landing_about: (
    <>
      <h1>About us</h1>
      <h2>
        <i>
          Established in 2011, Petit Garage offers a complete range of auto
          services, including damage assessment center, auto mechanics, auto
          electrics, bodywork, auto painting, and computerized testing.
        </i>
      </h2>
      <p>
        With a dedicated team and experience in the field, we provide
        comprehensive solutions for vehicle repair and maintenance, using modern
        technologies and professional equipment. We are committed to providing
        transparency and open communication in everything we do. We understand
        how important your car is to you, which is why we treat every vehicle
        with the same care and attention as if it were our own.
      </p>
      <p>
        At our auto service, we don't just stop at repairs. We strive to be a
        trusted resource for our customers, offering advice and recommendations
        for maintaining and improving your car's performance. We commit to
        exceeding the expectations of a simple auto service, aiming for
        excellence. We monitor carefully, intervene promptly and efficiently,
        respecting the highest standards, and adapting to any situation.
      </p>
      <p>
        Our goal is to become a reference point in the auto service market in
        Ploiești. We aspire to be a model of "exemplary services," not only for
        our customers but also for local competition and the entire auto service
        industry.
      </p>
      <p>
        The principles guiding us are: professionalism, dedication, efficiency,
        respect, and transparency, as well as continuous innovation. We
        carefully select our employees, provide them with rigorous training, and
        passionately involve ourselves in every project. We intervene quickly
        and efficiently in any situation and cultivate relationships based on
        respect and transparent communication with our customers. We constantly
        adapt to market needs and integrate the latest technologies in the field
        of auto services.
      </p>
      <p>
        We invite you to visit us and experience our quality and professionalism
        firsthand. Our team is here to ensure that your car is always in the
        best possible condition.
      </p>
      <p>Contact us for personalized solutions tailored to your auto needs.</p>
    </>
  ),

  testimonial_1: (
    <>
      <h1>Totalmontaj SRL</h1>
      <p>
        A company specialized in chemical equipment construction and
        installation in chemical plants and refineries, where we own a diverse
        fleet, from cars to cranes, found in Petite Garage auto service our
        partner for high-quality automotive services, with immediate
        availability and professionalism in addressing issues that arise, at
        realistic costs. We wholeheartedly recommend this service.
      </p>
    </>
  ),

  testimonial_2: (
    <>
      <h1>SC Daas impex SRL</h1>
      <p>
        Company with activities in refrigeration equipment, Horeca equipment,
        mobile commerce, and service equipment, we have been collaborating with
        Petit Garage since 2014. Their professional and dedicated team has
        managed to provide high-quality car services for our vehicle fleet. We
        confidently recommend Petit Garage for any auto services, being sure
        that they will do everything possible to meet your needs and keep your
        vehicles in the best possible condition.
      </p>
    </>
  ),
};

import React from "react";
import "./footer.css"; // Asigură-te că această cale este corectă

const Footer = () => {
  return (
    <>
      <div className="Footer">
        <div className="container">
          <div className="row">
            {/* First Section */}
            <div className="col-md-4 ft-1">
              <h3>
                <span>WALEED</span>CODES
              </h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum
                ea quo ex ullam laboriosam magni totam, facere eos iure
                voluptate.
              </p>
              <div className="footer-icons">
                <i className="fa-brands fa-facebook"></i>
                <i className="fa-brands fa-twitter"></i>
                <i className="fa-brands fa-instagram"></i>
                <i className="fa-brands fa-linkedin-in"></i>
              </div>
            </div>
            {/* Second Section */}
            <div className="col-md-4 ft-2">
              <h5>Quick Links</h5>
              <ul>
                <li className="nav-item">
                  <a href="/">Services</a>
                </li>
                <li className="nav-item">
                  <a href="/">Portfolio</a>
                </li>
                <li className="nav-item">
                  <a href="/">Contact Us</a>
                </li>
                <li className="nav-item">
                  <a href="/">About Us</a>
                </li>
              </ul>
            </div>
            {/* Third Section */}
            <div className="col-md-4 ft-3">
              <h5>Contact</h5>
              <p>
                <i className="fa-solid fa-phone-volume"></i> +92 3121324083
              </p>
              <p>
                <i className="fa-solid fa-envelope"></i>{" "}
                waleedishfaq1515@gmail.com
              </p>
              <p>
                <i className="fa-solid fa-paper-plane"></i> Abbottabad,
                Pakistan.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="Last-footer">
        <p>© Petite Garage. All Rights Reserved.</p>
      </div>
    </>
  );
};

export default Footer;

import React, { useState } from "react";
import { useCookies } from "react-cookie";
import "./CookieBanner.css"; // Pentru stilizarea bannerului

const CookieBanner = () => {
  const [cookies, setCookie] = useCookies(["user"]);
  const [vizibil, setVizibil] = useState(!cookies.acceptedCookies);

  const acceptaCookieuri = () => {
    setCookie("acceptedCookies", true, { path: "/" });
    setVizibil(false);
  };

  if (!vizibil) {
    return null;
  }

  return (
    <div className="cookie-banner">
      <p>
        Acest site folosește cookie-uri pentru a vă asigura cea mai bună
        experiență pe site-ul nostru.
      </p>
      <div className="cookie-banner-buttons">
        <button onClick={acceptaCookieuri}>Acceptă</button>
      </div>
    </div>
  );
};

export default CookieBanner;
